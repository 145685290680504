:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #6d44fb;
  --toastify-color-success: #25282d;
  --toastify-color-warning: #fcd75c;
  --toastify-color-error: #eb4e3d;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  --toastify-text-color-light: #25282d;
  --toastify-toast-width: 400px;
}

.Toastify__toast {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  border-radius: 16px;
  padding: 8px 16px;
}

.Toastify__close-button {
  height: 24px;
  width: 24px;
  color: #25282d;
}

.Toastify__close-button > svg {
  height: 24px;
  width: 24px;
  margin-top: 11px;
}
